const loadGoogleMapsApi = require('load-google-maps-api');

var map;
var markers = [];
var currentMarker = null;
class Map {

    // the smooth zoom function 
    static smoothZoom(map, max, cnt) {
        if (cnt >= max) {
            // setTimeout(function() { currentMarker.setAnimation(null); }, 1000);
            return;
        } else {
            var z = google.maps.event.addListener(map, 'zoom_changed', function(event) {
                google.maps.event.removeListener(z);
                Map.smoothZoom(map, max, cnt + 1);
            });
            setTimeout(function() { map.setZoom(cnt) }, 150);
        }
    }

    static loadGoogleMapsApi() {
        return loadGoogleMapsApi({ key: 'AIzaSyBXWKuWRyIbZjA2WaP8WjYWdQ-uCi6Ma6w' });
    }
    static createMarker(map, locations) {
        /* Déclaration de l'objet qui définira les limites de la map */
        let bounds = new google.maps.LatLngBounds();

        var num_markers = locations.length;
        var infowindow = new google.maps.InfoWindow({
            pixelOffset: new google.maps.Size(0, -3)
        });

        for (var i = 0; i < num_markers; i++) {
            var icon = {
                url: "src/assets/images/pin@2x.png", // url
                scaledSize: new google.maps.Size(50, 50), // scaled size
                origin: new google.maps.Point(0, 0), // origin
                anchor: new google.maps.Point(25, 50) // anchor
            };

            var marker = new google.maps.Marker({
                position: { lat: locations[i][1], lng: locations[i][2] },
                map: map,
                html: locations[i][3],
                id: i,
                icon: icon
            });

            google.maps.event.addListener(marker, 'click', (function(marker, i) {
                return function() {
                    infowindow.setContent(locations[i][3]);
                    infowindow.open(map, marker);
                }

            })(marker, i));

            // Add marker to markers array
            markers.push(marker);
            bounds.extend(markers[i].getPosition());


            // var infowindow = new google.maps.InfoWindow({
            //     id: this.id,
            //     content: this.html,
            //     position: this.getPosition(),
            //     pixelOffset: new google.maps.Size(0, -30)
            // });
            // google.maps.event.addListenerOnce(infowindow, 'closeclick', function() {
            //     markers[this.id].setVisible(true);
            // });
            // this.setVisible(false);
            // infowindow.open(map);

        }


        // Récupération de l'id de la map en cours
        let idMap = map.getDiv();
        console.log(idMap.id)


        if (!bounds.isEmpty()) {
            var originalMaxZoom = map.maxZoom;
            map.setOptions({ maxZoom: 14 });
            if (idMap.id == 'mapContact' && $(window).width() > 1200) {
                map.fitBounds(bounds, { left: 700, right: 200 });
            } else {
                map.fitBounds(bounds, 150);
            }

            setTimeout(function() {
                map.setOptions({ maxZoom: originalMaxZoom });
            }, 300);

        }




        $('.marker-link').on('click', function() {
            google.maps.event.trigger(markers[$(this).data('markerid')], 'click');
            var _this = this;
            map.setCenter(markers[$(_this).data('markerid')].getPosition()); // set map center to marker position 
            setTimeout(function() {
                Map.smoothZoom(map, 16, map.getZoom());
            }, 600);
        });


        $('.marker-link-contact').on('click', function() {
            if (currentMarker) { // Check if there is already an active marker
                currentMarker.setAnimation(null);
            }
            currentMarker = markers[$(this).data('markerid')];
            // google.maps.event.trigger(markers[$(this).data('markerid')], 'click');
            markers[$(this).data('markerid')].setAnimation(google.maps.Animation.BOUNCE);

            map.setCenter(markers[$(this).data('markerid')].getPosition()); // set map center to marker position 

            setTimeout(function() { Map.smoothZoom(map, 16, map.getZoom()); }, 600);
        });
    }
    static createMap(googleMaps, mapElement, zoom) {
        if (zoom < 10) {
            var center = new google.maps.LatLng(50.6333236, 3.5063198);
        } else {
            var center = new google.maps.LatLng(50.6333236, 4.6063198);
        }

        return new googleMaps.Map(mapElement, {
            zoom: zoom,
            maxZoom: 20,
            center: center,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [{
                    featureType: "landscape",
                    elementType: "geometry.fill",
                    stylers: [{
                        color: "#f2f2f2"
                    }]
                },
                {
                    elementType: "labels.icon",
                    stylers: [{
                        visibility: "off"
                    }]
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [{
                        color: "#616161"
                    }]
                },
                {
                    elementType: "labels.text.stroke",
                    stylers: [{
                        color: "#f5f5f5"
                    }]
                },
                {
                    featureType: "poi",
                    elementType: "geometry",
                    stylers: [{
                        color: "#eeeeee"
                    }]
                },
                {
                    featureType: "poi",
                    elementType: "labels.text.fill",
                    stylers: [{
                        color: "#757575"
                    }]
                },
                {
                    featureType: "poi.park",
                    elementType: "geometry",
                    stylers: [{
                        color: "#e5e5e5"
                    }]
                },
                {
                    featureType: "poi.park",
                    elementType: "labels.text.fill",
                    stylers: [{
                        color: "#9e9e9e"
                    }]
                },
                // {
                //     featureType: "road",
                //     elementType: "geometry",
                //     stylers: [{
                //         color: "#ffffff"
                //     }]
                // },
                {
                    featureType: "road.arterial",
                    elementType: "labels.text.fill",
                    stylers: [{
                        color: "#757575"
                    }]
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [{
                        color: "#dadada"
                    }]
                },
                {
                    featureType: "road.highway",
                    elementType: "labels.text.fill",
                    stylers: [{
                        color: "#616161"
                    }]
                },
                {
                    featureType: "road.local",
                    elementType: "labels.text.fill",
                    stylers: [{
                        color: "#9e9e9e",
                        visibility: "off"
                    }]
                },
                {
                    featureType: "transit",
                    elementType: "geometry",
                    stylers: [{
                        visibility: "off"
                    }]
                },
                {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [{
                            color: "#c9c9c9"
                        },
                        {
                            visibility: "on"
                        }
                    ]
                },
                {
                    featureType: "water",
                    elementType: "geometry.fill",
                    stylers: [{
                            color: "#8bd4e3"
                        },
                        {
                            visibility: "on"
                        },
                        {
                            weight: 2
                        }
                    ]
                },
                {
                    featureType: "water",
                    elementType: "labels.text",
                    stylers: [{
                        color: "#ffffff"
                    }]
                }
            ]
        });
    }



}
export { Map };